<template>
	<div>
		<secondary-page-layout :title="$t('confirmation')" v-if="!success">
	        <otp-verify v-model="code" :info="info" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>
	    </secondary-page-layout>
	    <success-info 
		    :data="data_success" 
		    :tariff="tariff"
		   	v-else />
	</div>
</template>
<script>
	import OtpVerify from '@/components/OTP/OtpVerify';
	import SuccessInfo from "@/components/SuccessInfo";

	export default {
	  	name: "AgreementVerification",
	  	components: {
	    	OtpVerify,
	    	SuccessInfo
	  	},
	  	computed: {
	  		info() {
	        	return { phone_number: '708 666 89 99'}
	    	},
	  	},
	  	mounted(){
	  		if(this.$route.params.data_success){
	  			this.data_success = this.$route.params.data_success;
	  		}

	  		if(this.$route.params.tariff){
	  			this.tariff = this.$route.params.tariff;
	  		}
	  	},
	  	data(){
		    return {
		      	errorMessage: null,
		      	code: '',
		      	calculationData: null,
		     	success: true,
		      	isShowTariff: null,
		      	data_success:{
			        title: '',
			        subtitle: '',
			    },
			    tariff: null,
		    }
	  	},
	  	methods: {
	  		verifyCode(){
	  			let loader = this.$loading.show();
	  			this.success = true;
	  			setTimeout(() => loader.hide(), 1000);
	  		},
	  		sendConfirmCode(){

	  		}
	  	}
	}
</script>