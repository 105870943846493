<template>
	<div class="success-info--wrap">
		<div class="head">
			<img src="/img/Success.svg">
			<p class="title">{{ data.title }}</p>
			<p class="subtitle">{{ data.subtitle }} 
				<template v-if="data.haveDate">{{ tariff.date }}</template>
			</p>
		</div>
		<div class="tariff-wrap" v-if="tariff">
			<div class="item">
				<p class="title">{{$t('yourRate')}}</p>
				<p class="info">Легкий</p>
			</div>
			<div class="wrap-items">
				<div class="item">
					<p class="title">{{$t('monthlyPayment')}} </p>
					<p class="info green">490 ₸</p>
				</div>
				<div class="item">
					<p class="title">{{$t('firstPaymentDate')}}</p>
					<p class="info">21 мая</p>
				</div>
			</div>
		</div>

		<div class="final-amount-wrapper fixed">
    		<div class="final-amount bg-not">
    			<router-link :to="{name: 'Welcome'}" class="primary-btn">
			      {{$t('understand')}}
			    </router-link>
    		</div>
    	</div>
	</div>
</template>
<script>
	export default {
  	name: "SuccessInfo",
  	props: {
	    data: {
	      type: Object,
	    },
	    tariff: {
	      type: Object,
	    },
	},
}
</script>
<style lang="scss">
	.success-info--wrap{
		.head{
			background: #2AA65C;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 40px 50px 120px;
			img{
				margin-bottom: 30px;
			}
			.title{
				font-family: 'Manrope';
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				color: #FFFFFF;
			}
			.subtitle{
				font-family: 'Manrope';
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				color: #FFFFFF;
			}
		}

		.tariff-wrap{
			padding: 0px 16px;
			.item{
				background: #FFFFFF;
				border: 1px solid #F1F2F6;
				border-radius: 12px;
				margin-bottom: 8px;
				padding-top: 16px;
				padding-bottom: 16px;
				.title{
					font-family: 'Manrope';
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					text-align: center;
					color: #99A3B3;
					margin-bottom: 8px;
				}
				.info{
					font-family: 'Manrope';
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					color: #071222;
					margin-bottom: 0px;
					&.green{
						color: #2AA65C;
					}
				}
			}

			.wrap-items{
				display: flex;
				margin-left: -4px;
				margin-right: -4px;
				.item{
					margin-left: 4px;
					margin-right: 4px;
					width: 100%;
					padding-left: 20px;
    				padding-right: 20px;

				}
			}
		}
	}

	.final-amount-wrapper.fixed{
		position: fixed;
		margin: 0;
    	width: 100%;
	}
</style>